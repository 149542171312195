<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="drawer"/>
        <v-container
          v-scroll.self="onScroll"
        >
          <v-row class="mt-5">
            <v-col cols="8">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
                <v-card-title class="subtitle-2">ANTOLOGIAS</v-card-title>
                <v-card-text
                  class="black--text"
                  v-html="DadosTexto.Texto"
                ></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div id="galeria" class="mt-5">
                <vue-picture-swipe :items="items"></vue-picture-swipe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="drawer"/>
        <div class="container" id="topoantologias" style="margin-bottom: 20vh;">
          <span class="mb-2"><a href="#galeria" class="linkGaleria">Galeria</a></span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-card-title class="subtitle-2">ANTOLOGIAS</v-card-title>
            <v-card-text
              class="black--text"
              v-html="DadosTexto.Texto"
            ></v-card-text>
          </v-card>
          <div id="galeria" class="mt-5">
            <vue-picture-swipe :items="items"></vue-picture-swipe>
          </div>
          <span><a href="#topoantologias" class="linkGaleria">Topo</a></span>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'Antologias',
  components: {
    AppBar
  },
  data () {
    return {
      drawer: false,
      DadosTexto: {
        Titulo: "ANTOLOGIAS",
        Texto: `As antologias poéticas são coletâneas formadas por diferentes poemas organizados em um volume. Podem ser de diversas obras literárias de um mesmo autor, ou, sendo de diversos autores, costumam abranger um mesmo tema ou momento histórico.<br /><br />
                Sabendo do alto investimento necessário (em muitos sentidos) para a publicação de um livro autoral e trilhando um caminho comum a muitos autores que iniciam assim sua participação no universo literário, poemas inéditos da autora foram incluídos em algumas antologias.<br /><br />
                A primeira de todas as antologias foi a obra “Enquanto Espero”, pela Editora Litteris. Conta com dois poemas de Daniela Laubé e retrata, pelos olhos da literatura, um momento histórico da pandemia de Covid-19. <br /><br />
                Outra, de especial apreço, é a Antologia de Aldravias, modalidade de poesia minimalista genuinamente brasileira, precisamente criada em Minas Gerais, na cidade de Mariana. A autora foi convidada a integrar a obra na qualidade de declaradamente interessada na forma poética do movimento aldravianista.<br /><br />
                Adquirindo uma dessas antologias é possível levar um pouquinho do início de sua história.
                <br /><br />
        `
      },
      items: [{
          src: 'https://cdn.discordapp.com/attachments/999154127621857360/999154546309877901/antologias_5.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154127621857360/999154591277010974/antologias_5.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154127621857360/999154546628628480/antologias_4.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154127621857360/999154590979194890/antologias_4.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154127621857360/999154547010306109/antologias_3.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154127621857360/999154590568161280/antologias_3.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154127621857360/999154547341676605/antologias_2.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154127621857360/999154590266179594/antologias_2.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154127621857360/999154548050501652/antologias_6.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154127621857360/999154589666386020/antologias_6.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154127621857360/999154547744313374/antologias_1.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154127621857360/999154589985157241/antologias_1.jpeg',
          w: 503,
          h: 670
        }
      ]
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    }
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
